<template>
<div class="tw-w-full tw-flex-col">
  <slot name="header" >

  </slot>
  <slot name="topContent">

  </slot>
  <slot name="footer">

  </slot>
</div>
</template>

<script>
export default {
  name: "PageLayout"
}
</script>

<style scoped>

</style>