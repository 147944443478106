<template>
  <div class="d-flex align-center size">
    <div class="">
      {{ sizeName }}
    </div>
    <div>-{{ count }}</div>
  </div>
</template>

<script>
export default {
  name: "SizeAndCount",
  props: {
    size: String,
    count: [Number, String]
  },
  computed: {
    sizeName () {
      const sizes = {
        // S: 'Small',
        // M: 'Medium',
        // L: 'Large',
        // XL: 'Extra Large',
        // XXL: 'Extra Large Large'
        S: 'S',
        M: 'M',
        L: 'L',
        XL: 'XL',
        XXL: 'XXL'
      }
      return sizes[this.size]
    }
  }
}
</script>

<style scoped lang="scss">
.size {
  //background: #FFF8EF;
  //border: 1px solid #FF7A01;
  //color: #FF7A01;
  //font-size: 13px;
  //font-weight: 500;
  //line-height: 17px;
  border-radius: 20px;
  padding: 2px 8px;
  cursor: default;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  /* smoke */

  color: #514A41;
}
</style>
