import { uploadToCloudinary } from "@/services/user-profile";

export const uploadDocumentToCloudinary = async (file, folder = "media") => {
  const formData = new FormData();
  formData.append('upload_preset', 'kneekpreset'); // upload preset
  formData.append("folder", folder);
  formData.append("file", file);
  const {
    data: {
      secure_url: url,
      original_filename: name,
      bytes: size
    }
  } = await uploadToCloudinary(formData);
  return {
    url,
    name,
    size
  }
}


export const hasDesignOn = (item, where = 'front') => {
  if (item[`${where}_url`]) {
    return !!JSON.parse(item[`${where}_url`]).objects.length
  }
  return false
}
export const getItemPrice = (item) => {
  if (!item) return 0
  const basePrice = 2200
  const priceOfShirt = 1300
  const designPrice = 1000
  const amountOfOneQty = basePrice + priceOfShirt + (hasDesignOn(item) ? designPrice : 0) + (hasDesignOn(item, 'back') ? designPrice : 0)
  return amountOfOneQty * (Object.values(item.sizes).reduce((curr, acc) => curr + acc, 0))
}
