<template>
  <div class="">
    <div class="pb-3" v-if="displaySummary">
      <div class="tw-flex">
        <v-avatar color="#F9F4EE" size="30">
          <span  class="black--text" v-if="shippingDetails.first_name">{{ shippingDetails.first_name.charAt(0).toUpperCase() }}</span>
        </v-avatar>
        <div class="tw-ml-4 shipping">
          <h2>{{ shippingDetails.first_name }} {{ shippingDetails.last_name }}</h2>
          <span>{{ shippingDetails.address }}</span>
          <p>{{ shippingDetails.city }}, {{ shippingDetails.state }}</p>
        </div>
      </div>
        <v-divider/>
    </div>
    <shipping-order-summary-item v-for="(cart) in selectedCartsItem" :item="cart" :key="cart.id" />
    <div>
      <div class="total my-6">
        <div class="d-flex justify-space-between">
          <h4>Subtotal</h4>
          <h4>&#8358;{{ subTotal }}</h4>
        </div>
        <div class="d-flex justify-space-between">
          <h4>Shipping</h4>
          <h4>N/A</h4>
        </div>
        <div class="d-flex justify-space-between total--price">
          <h4>Total</h4>
          <h4>&#8358;{{ subTotal }}</h4>
        </div>
        <div class="d-flex justify-space-between"></div>
      </div>
    </div>
    <div v-if="displaySummary" class="tw-flex tw-flex-col tw-justify-center tw-items-center">
      <paystack
          :email="getUserEmail"
          :amount="amount_before_format * 100"
          :reference="'PA'+reference"
          :paystackkey="key"
          :callback="callback"
          :close="close"
      >
        <v-btn class="proceed-btn tw-mr-3" :width="$vuetify.breakpoint.mdAndUp?'350':'300'" height="48" style="background-color: #FF7A01" text block>
                        PAY
        </v-btn>
      </paystack>

      <v-img class="paystack tw-mt-6" contain src="@/assets/paystack.png"/>
    </div>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          persistent
          max-width="375"
          width="375"
        >

          <v-card class="tw-px-4 tw-py-6 card">
            <v-card-title class="text-h5">
              Oops! We couldn’t process your order
            </v-card-title>
            <v-card-text>
              Something went wrong with the payment, please try again or cancel your order.
            </v-card-text>
            <v-card-actions class="">
                <paystack
                  :email="getUserEmail"
                  :amount="amount_before_format"
                  :reference="'PA'+reference"
                  :paystackkey="key"
                  :callback="callback"
                  :close="close"
                >
                  <v-btn class="proceed-btn tw-mr-3" width="115" height="42" style="background-color: #FF7A01" text >

                    Try again
                  </v-btn>

                </paystack>
              <v-btn class="proceed-btn tw-mr-3" width="115" height="42" outlined color="#FF7A01"  @click="dialog = false">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

  </div>
</template>

<script>
import cartRetrieverMixin from "@/mixins/cartRetrieverMixin";
import ShippingOrderSummaryItem from "@/components/shipping/ShippingOrderSummaryItem";
import priceMixin from "@/mixins/priceMixin";
import paystack from 'vue-paystack';
import { verifyPayment } from "@/services/auth-services";
import {getItemPrice} from "@/services/helpers";


export default {
  name: "OrderSummary",
  components: {ShippingOrderSummaryItem, paystack},
  mixins: [cartRetrieverMixin, priceMixin],
  data(){
    return{
      key:process.env.VUE_APP_PAY_STACK_KEY,
      selectedCartsItem:[],
      amount:0,
      dialog:false,
      // amount_before_format :0
    }
  },
  props:{
    shippingDetails:[Object],
    displaySummary:[Boolean],

  },
  created() {
    this.selectedCartsItem = JSON.parse(localStorage.getItem('selectedCartItem'))
  },
  computed: {
    subTotal () {
      return this.formatPrice(this.selectedCartsItem.reduce((acc, curr) => {
        console.log(curr, acc)
        return getItemPrice(curr) + acc
      }, 0))
    },
    amount_before_format () {
      return this.subTotal?.split(',')?.join('') ?? 0
    },
    getUserEmail() {
      const user = JSON.parse(localStorage.getItem("userData"));
      return user.email
    },
    selectedItemInCart(){
      return JSON.parse(localStorage.getItem('selectedCartItem')) || null
    },
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      // this.flutterRef += possible.charAt(Math.floor(Math.random() * possible.length)+this.getUserEmail.charAt(0))
      return text+this.getUserEmail.charAt(0);
    },
  },
  methods:{
    close: () => {
      console.log("You closed checkout page")
    },
    $getItemPrice: (item) => getItemPrice(item),
    callback: async function (response) {
      console.log(response)
      this.dialog = false
      // if(response.status === 'success' && response.message === 'Approved') {
      this.loading = true
      await verifyPayment({
        reference: response.reference,
        shipping: this.shippingDetails,
        order: {order_items: this.selectedCartsItem},
      }).then(res => {
        console.log(res)
        if(res.data.detail === 'success'){
          this.loading = false
          // this.removeItemFromStorageAfterPaying()
          localStorage.removeItem('selectedCartItem')
          this.$router.push({name:'PaymentNotification',query:{status:'success'}})
        }
        else{
          this.loading = false
          this.dialog=true


        }
      })
        .catch(err => {
          console.log(err)
          this.loading = false
          this.dialog=true

        })
      // }
    },


  }
}
</script>

<style scoped lang="scss">
.total {
  & > div {
    margin: 20px 0;
  }

  h4 {
    font-size: 15px;
  }

  h4:first-child {
    font-weight: 500;
  }

  h4:last-child {
    font-weight: 700;
  }
}

.total--price {
  h4 {
    font-weight: 700 !important;
  }
}
.proceed-btn {
  border-radius: 100px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.paystack {
  width: 224.8px;
  height: 65.52px;
  @media screen and (max-width: 1024px) {
    width: 100%;
    height: auto;
    margin-top: 1rem;

  }
}
.shipping h2{
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}
.shipping span p{
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #514A41;
}
.card{
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
}
</style>
