<template>
<div>
  <v-progress-circular
      :size="70"
      :width="7"
      color="#FF7A01"
      indeterminate
  ></v-progress-circular>
</div>
</template>

<script>
export default {
name: "Loader"
}
</script>

<style scoped>

</style>
