export default {
  state: [
    // "Abia",
    // "Adamawa",
    // "Akwa Ibom",
    // "Anambra",
    // "Bauchi",
    // "Bayelsa",
    // "Benue",
    // "Borno",
    // "Cross River",
    // "Delta",
    // "Ebonyi",
    // "Edo",
    // "Ekiti",
    // "Rivers",
    // "Enugu",
    // "FCT",
    // "Gombe",
    // "Imo",
    // "Jigawa",
    // "Kaduna",
    // "Kano",
    // "Katsina",
    // "Kebbi",
    // "Kogi",
    // "Kwara",
    "Lagos Mainland",
    "Lagos Island",
    // "Nasarawa",
    // "Niger",
    // "Ogun",
    // "Ondo",
    // "Osun",
    // "Oyo",
    // "Plateau",
    // "Sokoto",
    // "Taraba",
    // "Yobe",
    // "Zamfara"
  ]
};
