<template>
  <div class="py-5 summary--item">
    <div class="d-flex justify-space-between title--and--price">
      <h5>{{ name  }} <span>({{ totalCount }})</span></h5>
      <p class="mb-0">&#8358;{{ formatPrice(price) }} each</p>
    </div>
    <div class="color--case d-flex my-3">
      <div class="color--indicator" :data-color="color" />
      Black
    </div>
    <div class="d-flex">
      <div>
        <v-img
            width="40"
          :src="image"
          />
      </div>
      <div class="flex-grow-1 ml-3">
        <size-and-count class="my-2" v-for="(count, size) in item.sizes" :key="size" :size="size" :count="count" />
      </div>
      <div>
      </div>
    </div>
  </div>
</template>

<script>
import priceMixin from "@/mixins/priceMixin";
import SizeAndCount from "@/components/shipping/SizeAndCount";
import {getItemPrice} from "@/services/helpers";

export default {
  name: "ShippingOrderSummaryItem",
  components: {SizeAndCount},
  mixins: [priceMixin],
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    name () {
      return this.item.name || this.item.merch_data.merch_name
    },
    totalCount () {
      return Object.keys(this.item.sizes).reduce((acc, curr) => acc + this.item.sizes[curr], 0)
    },
    image () {
      return this.item.shirt || this.item.image
    },
    price () {
      return getItemPrice(this.item)
    },
    color () {
      return this.item.color ?? '#000'
    }
  },
  created () {
    Object.keys(this.item.sizes).forEach(size => !this.item.sizes[size] && delete this.item.sizes[size])
  }
}
</script>

<style scoped lang="scss">
.summary--item {
  border-bottom: 1px solid #EAEAEA;
}

.color--indicator[data-color] {
  background: attr(data-color, black);
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.title--and--price {
  color: #514A41;
  font-size: 15px;
  font-weight: 500;

  & > span {
    font-weight: 400;
  }
}
</style>
