<template>
  <div class="tw-w-full tw-flex">
    <page-layout>
      <template #header>
        <landing-page-header/>
      </template>

      <template #topContent>
        <div v-if="loading" class="tw-flex tw-justify-center tw-items-center">
            <Loader/>
        </div>
        <v-container v-else class="tw-mt-6 max-size">
          <div class="tw-flex tw-items-centerm tw-flex-col-reverse md:tw-flex-row md:tw-px-20  tw-w-full md:tw-mt-10">
            <ValidationObserver v-slot="{ validate, invalid }" @submit.prevent="validate().then(displaySummary=true)" v-if="!displaySummary" class="tw-w-full lg:tw-w-3/5 tw-flex-col lg:tw-pr-20">
            <div >
              <h6 class="shipping-address-title tw-pb-5 tw-mt-10 lg:tw-mt-0">Shipping Address</h6>

              <div class="tw-flex tw-pb-8 tw-mt-6 md:tw-mt-0 flex-wrap">
                <simple-tabs v-model="itemToDisplay" :tabs="tabs" />
              </div>

              <div v-if="itemToDisplay==='Existing Address'">
                <div class="wrapper tw-flex md:tw-flex-row tw-flex-col tw-items-center mt-md-n16" v-if="!shippingAddresses.length">
                  <img src="@/assets/addressImg.svg" alt="frame">
                  <div class="md:tw-ml-8 tw-mt-4 tw-text-center md:tw-text-left">
                    <h4>No Saved Addresses</h4>
                    <p>Saved delivery address to make<br/>
                      your orders faster.</p>
                  </div>
                </div>
                <v-card @click="selectAddress(address)" v-if="shippingAddresses.length" v-for="address in shippingAddresses" :key="address.address" class="tw-p-4 tw-mb-3" outlined>
                  <div class="tw-flex tw-justify-between tw-w-full">
                    <p class="order-title">{{ address.first_name + ' ' + address.last_name }}</p>
                    <div>
                      <label v-if="address.address !== selectedAddress.address" class="not-selected tw-px-4 tw-py-1 tw-items-center tw-justify-center tw-flex" style="font-size: 14px">SELECT</label>
                      <label v-if="address.address === selectedAddress.address" class="size-wrapper tw-px-4 tw-py-1 tw-items-center tw-justify-center tw-flex" style="font-size: 14px">SELECTED</label>
                    </div>
                  </div>
                  <p class="display-address">{{ address.address }}</p>
                  <p class="display-address">{{ address.city + ' ' + address.state }}.</p>
                </v-card>

                <div class="tw-flex tw-w-full lg:tw-flex-row tw-items-center tw-mt-20  tw-flex-col tw-pb-80 lg:tw-pb-0">
                  <v-btn :disabled="selected" class="proceed-btn tw-mr-3 tw-mb-4 md:tw-mb-0"  :width="$vuetify.breakpoint.mdAndUp?'200':''" height="48"  color="#FF7A01" depressed  @click="displaySummary=true">
                    NEXT
                  </v-btn>
                  <v-btn class="proceed-btn" height="48"  outlined color="#FF7A01" @click="$router.push({name:'Cart'})">
                    BACK TO CART
                  </v-btn>
                </div>
              </div>

              <div v-if="addNewAddress || itemToDisplay==='New Address'" class="tw-flex tw-w-full tw-flex-col">
                <div class="tw-flex tw-w-full tw-justify-between tw-flex-row ">
                  <div class="tw-flex tw-flex-col shipping-address-width">
                    <label class="shipping-address-label tw-pb-1">First Name</label>
                    <ValidationProvider
                      name="first Name"
                      rules="required"
                      v-slot="{ errors }">
                    <v-text-field
                        id="firstName"
                        color="#FF7A01"
                        height="44"
                        outlined
                        v-model="shippingAddress.first_name"
                        style="border-radius: 10px"></v-text-field>
                      <span class="err">{{ errors[0] }}</span>

                    </ValidationProvider>
                  </div>
                  <div class=" tw-flex tw-flex-col shipping-address-width ">
                    <label class="shipping-address-label tw-pb-1">Last Name</label>
                    <ValidationProvider
                      name="last Name"
                      rules="required"
                      v-slot="{ errors }">
                    <v-text-field
                        id="lastName"
                        color="#FF7A01"
                        height="44"
                        outlined
                        v-model="shippingAddress.last_name"

                        style="border-radius: 10px"></v-text-field>
                      <span class="err">{{ errors[0] }}</span>

                    </ValidationProvider>
                  </div>
                </div>
                <div class="tw-flex tw-flex-col tw-w-full tw-mt-5">
                  <label class="shipping-address-label tw-pb-1">Phone Number</label>
                  <ValidationProvider
                      v-slot="{ errors }"
                      name="Phone Number"
                      rules="required">
                    <VuePhoneNumberInput
                        v-model="shippingAddress.phone_number"
                        class="mb-1"
                        default-country-code="NG"
                        errorColor="red"
                        color="#DDDDDD"
                        clearable
                        size="lg"/>
<!--                    <v-text-field-->
<!--                        v-model="shippingAddress.address"-->
<!--                        label="Phone Number"-->
<!--                        mask="###-###-####"-->
<!--                        outlined-->
<!--                        persistent-hint-->
<!--                        :rules="[phoneNumberRule]"-->
<!--                    ></v-text-field>-->
                    <span class="err">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="tw-flex tw-flex-col tw-w-full tw-mt-5">
                  <label class="shipping-address-label tw-pb-1">Address</label>
                  <ValidationProvider
                    name="Address"
                    rules="required"
                    v-slot="{ errors }">
                  <v-text-field
                      id="address"
                      color="#FF7A01"
                      height="44"
                      outlined
                      v-model="shippingAddress.address"

                      style="border-radius: 10px"></v-text-field>
                    <span class="err">{{ errors[0] }}</span>

                  </ValidationProvider>
                </div>
                <div class="tw-flex tw-w-full tw-justify-between tw-flex-row tw-mt-5">
                  <div class="tw-flex-col shipping-address-width">
                    <label class="shipping-address-label tw-pb-1">Location</label>
                    <ValidationProvider
                      name="State"
                      rules="required"
                      v-slot="{ errors }">
                    <v-combobox
                        id="state"
                        v-model="shippingAddress.state"
                        :items="states.state"
                        color="#FF7A01"
                        height="44"
                        outlined
                        style="border-radius: 10px"></v-combobox>
                      <span class="err">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="tw-flex tw-flex-col shipping-address-width">
                    <label class="shipping-address-label tw-pb-1">City</label>
                    <ValidationProvider
                      name="City"
                      rules="required"
                      v-slot="{ errors }">
                    <v-combobox
                        id="city"
                        :items="getLga"
                        color="#FF7A01"
                        height="44"
                        v-model="shippingAddress.city"
                        outlined
                        style="border-radius: 10px"></v-combobox>
                      <span class="err">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="tw-flex tw-flex-col tw-w-full tw-mt-5">
                  <label class="shipping-address-label tw-pb-1">Additional Information e.g. Landmarks</label>
                  <ValidationProvider
                    name="Addition Info"
                    v-slot="{ errors }">
                  <v-text-field
                      id="landmark"
                      color="#FF7A01"
                      height="44"
                      outlined
                      v-model="shippingAddress.additional_information"
                      style="border-radius: 10px"></v-text-field>
                    <span class="err">{{ errors[0] }}</span>

                  </ValidationProvider>
                </div>
                <div class="tw-flex tw-w-full tw-flex-row tw-items-center">
                  <v-checkbox
                      id="landmark"
                      color="#FF7A01"
                      height="44"
                      outlined
                      v-model="shippingAddress.save_shipping_address"
                      style="border-radius: 10px"></v-checkbox>
                  <label class="shipping-address-label" @click="toggleCheckbox">Save Shipping Address</label>
                </div>

                <div class="tw-flex tw-w-full lg:tw-flex-row tw-items-center tw-mt-20  tw-flex-col tw-pb-80 lg:tw-pb-0">
                  <!--                <v-btn class="proceed-btn tw-mr-3" width="200" height="48" style="background-color: #FF7A01" text>-->
                  <!--                  <paystack-->
                  <!--                    :email="getUserEmail"-->
                  <!--                    :amount="7000 * 100"-->
                  <!--                    :reference="'PA'+reference"-->
                  <!--                    :paystackkey="key"-->
                  <!--                    :callback="callback"-->
                  <!--                    :close="close"-->
                  <!--                  >-->
                  <!--                    NEXT-->
                  <!--                  </paystack>-->
                  <!--                </v-btn>-->
                  <v-btn :disabled="invalid" class="proceed-btn tw-mr-3 tw-mb-4 md:tw-mb-0"  :width="$vuetify.breakpoint.mdAndUp?'200':''" height="48"  color="#FF7A01" depressed  @click="displaySummary=true">

                    NEXT
                  </v-btn>
                  <v-btn class="proceed-btn" height="48"  outlined color="#FF7A01" @click="$router.push({name:'Cart'})">
                    BACK TO CART
                  </v-btn>
                  <!--                <v-img class="paystack" contain src="../assets/paystack.png"/>-->
                </div>
              </div>


<!--              <div v-if="shippingAddresses.length && !addNewAddress"  class="tw-flex tw-items-center tw-my-10 tw-cursor-pointer"-->
<!--                   style="width: max-content" @click="addNewAddress=true">-->
<!--                <vue-feather style="color: #FF7A01" type="plus"/>-->
<!--                <label class="shipping-title tw-cursor-pointer">Add New Address</label>-->
<!--              </div>-->

              <!--              <h6 class="shipping-address-title">Promo Code</h6>-->
<!--              <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-mt-5">-->
<!--                <div class="tw-flex tw-items-center tw-mr-5 tw-w-3/4 lg:tw-w-2/4" style="height: 44px">-->
<!--                  <v-text-field-->
<!--                      id="promo-code"-->
<!--                      color="#FF7A01"-->
<!--                      outlined-->
<!--                      style="border-radius: 10px;"></v-text-field>-->
<!--                </div>-->
<!--                <v-btn class="shipping-btn" height="48" style="background-color: #514A41" text width="84">Apply</v-btn>-->
<!--              </div>-->
            </div>
            </ValidationObserver>

            <div class="tw-w-full lg:tw-w-2/5 " :class="displaySummary?'tw-m-auto':'md:tw-mt-12'">
              <div class="tw-text-center tw-flex order" v-if="displaySummary">
                <v-icon class="tw-cursor-pointer" @click="displaySummary=false">
                  mdi-arrow-left
                </v-icon>
                <h3 class="tw-ml-16">Order Summary</h3>
              </div>
              <v-card class="order-card tw-p-5" elevation="0">
                <p class="order-title" v-if="!displaySummary">Order Summary</p>
                <order-summary :display-summary="displaySummary" :shipping-details="Object.keys(selectedAddress).length === 0? shippingAddress : selectedAddress"/>

              </v-card>

            </div>
          </div>

        </v-container>
      </template>

    </page-layout>
  </div>
</template>

<script>
import LandingPageHeader from "@/components/LandingPageHeader";
import PageLayout from "@/reuseable/PageLayout";
import state from "@/assets/state"
import LGA from "@/assets/lga"
import OrderSummary from "@/components/shipping/OrderSummary";
import {  getShippingAddress, verifyPayment } from "@/services/auth-services";
import Loader from "@/reuseable/Loader";
import SimpleTabs from "@/components/SimpleTabs.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
export default {
  name: "Shipping",
  components: {SimpleTabs, Loader, VuePhoneNumberInput, OrderSummary, PageLayout, LandingPageHeader,},
  data() {
    return {
      states: state,
      LGA,
      data: {
        state: ""
      },
      orderList: [
        {
          productName: "Kanins Original Tee",
          productColorList: [
            {
              productColorName: "Black",
              productColorCode: "black",
              productColorImage: "https://res.cloudinary.com/kneenk/image/upload/v1631242695/black-shirt_rs29ed.png",
              productColorSizes: [
                {
                  size: "M",
                  selected: 3,
                  price: 3000
                },
                {
                  size: "L",
                  selected: 4,
                  price: 1000
                }
              ],
              showSub: false,
              iconType: "chevron-up"
            },
            {
              productColorName: "Grey",
              productColorCode: "grey",
              productColorImage: "https://res.cloudinary.com/kneenk/image/upload/v1631242809/shortsleeve_yuijy9.png",
              productColorSizes: [
                {
                  size: "XL",
                  selected: 4,
                  price: 2300
                },
              ],
              showSub: false,
              iconType: "chevron-up"
            }
          ]
        }
      ],
      shippingAddresses: [],
      shippingAddress: {
        first_name: "",
        last_name: "",
        address: "",
        phone_number: "",
        state: "",
        city: "",
        additional_information:'',
        save_shipping_address:false

      },
      addNewAddress: false,
      selectedAddress:{},
      displaySummary:false,
      loading:false,
      itemToDisplay:'New Address',
      tabs: ['New Address', 'Existing Address'],
      selected: true,
      shippingDetails: {}
    }
  },

  created () {
    // const addresses = JSON.parse(sessionStorage.getItem('addresses'))
    // addresses ? (this.shippingAddresses = addresses) : this.handleAddNewAddress()
    this.getAllAddress()
  },
  watch:{
    'itemToDisplay':{
      handler(val){
        if(val === 'New Address'){
          this.selectedAddress = {}
        }
      }
    },
    immediate: true,
    deep: true
  },
  methods: {
    toggleCheckbox(){
      this.shippingAddress.save_shipping_address = !this.shippingAddress.save_shipping_address
    },
    async getAllAddress(){

        this.loading = true
      getShippingAddress().then(
          res => {
            console.log(res)
            this.shippingAddresses=res.data
            this.loading = false

          }
        )
          .catch(err => {
            console.log(err)
            this.loading = false

          })
      },


    handleAddNewAddress() {
      this.shippingAddresses.push(this.shippingAddress)

      this.shippingAddress= {
        first_name: '',
          last_name: "",
          address: "",
          state: "",
          city: "",
          landmark:'',
        selected: false,
        add:false
      }
      this.addNewAddress =false
    },


    handleShowSub(colorName, showSub) {
      this.orderList.map((order) => {
        order.productColorList.map((product) => {
          if (colorName === product.productColorName) {
            product.showSub = !product.showSub
            if (product.iconType === "chevron-up") {
              product.iconType = "chevron-down"
            } else {
              product.iconType = "chevron-up"
            }
          }
        })
      })
    },
    selectAddress(address) {
      this.selectedAddress = address
      // this.shippingAddress = address
      this.selected = false
    }
  },
  computed: {
    getLga() {
      return LGA[this.shippingAddress.state] || []
    },

    calculateSubTotal() {
      let subtotal = 0
      this.orderList.map((order) => {
        order.productColorList.map((product) => {
          product.productColorSizes.map((size) => {
            subtotal += size.price
          })
        })
      })
      return subtotal
    },

    phoneNumberRule() {
      return (value) => {
        const regex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
        return regex.test(value) || 'Invalid phone number';
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.max-size {
  max-width: 75rem;
  @media screen and (max-width: 1024px) {
    max-width: 95%;
  }
}

.order-card {
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 8px;
}

.order-title {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}

.size-wrapper {
  background: #FFF8EF;
  border: 1px solid rgba(255, 122, 1, 0.32);
  box-sizing: border-box;
  border-radius: 100px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
  color: #FF7A01;
}
.not-selected{
  background: #fdf9f9 !important;
  border-color: #FFF8EF !important;
  border-radius: 100px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  color: #514A41;
}

.product-title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #514A41;
}

.shipping-title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  color: #514A41;
}

.shipping-title-bold {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: #514A41;
}

.shipping-address-title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.04em;
  color: #292929;
}

.shipping-address-label {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  cursor: pointer;
}

.shipping-address-width {
  width: 48%;
}

.shipping-btn {
  border-radius: 100px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.proceed-btn {
  border-radius: 100px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.paystack {
  width: 224.8px;
  height: 65.52px;
  @media screen and (max-width: 1024px) {
    width: 100%;
    height: auto;
    margin-top: 1rem;

  }
}

.display-address {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #514A41;
}
.order{
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;

  color: #000000;
  margin-bottom: 20px;
}
.wrapper{
  width: 90%;
  min-height: 200px;
  h4{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.03em;
    color: #514A41;
  }
  p{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #7B7777;
    padding-top: 6px;
  }
}
.err {
  display: block;
  color: red;
  font-size: 13px;
  margin-bottom: 5px;
}

</style>
